module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Strategica Partners","short_name":"Strategica","start_url":"/","background_color":"#1E3961","theme_color":"#1E3961","display":"standalone","icon":"static/icons/icon-144x144.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"hBXCY8Bnf1mcSbzMMyIw3zCalq1dkNE6","trackPage":true,"devKey":"hBXCY8Bnf1mcSbzMMyIw3zCalq1dkNE6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
