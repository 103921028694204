// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-case-study-tsx": () => import("./../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-blog-detail-tsx": () => import("./../src/templates/BlogDetail.tsx" /* webpackChunkName: "component---src-templates-blog-detail-tsx" */),
  "component---src-templates-download-detail-tsx": () => import("./../src/templates/DownloadDetail.tsx" /* webpackChunkName: "component---src-templates-download-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-how-to-conduct-market-research-tsx": () => import("./../src/pages/how-to-conduct-market-research.tsx" /* webpackChunkName: "component---src-pages-how-to-conduct-market-research-tsx" */),
  "component---src-pages-how-to-launch-a-product-tsx": () => import("./../src/pages/how-to-launch-a-product.tsx" /* webpackChunkName: "component---src-pages-how-to-launch-a-product-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-qatch-case-study-tsx": () => import("./../src/pages/qatch-case-study.tsx" /* webpackChunkName: "component---src-pages-qatch-case-study-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

